<template>
  <div
    class="wrap-game-practice h-100 overflow-auto"
    :class="{'jungo-wrap-game-practice': $isJungo}"
  >
    <PassAnimation
      v-if="isPassAnimation && $device.isMobile"
      :leave="isLeave"
      @animation-end="destoryPassAnimation"
    ></PassAnimation>
    <WebPassAnimation
      v-if="isPassAnimation && !$device.isMobile"
      :leave="isLeave"
      @animation-end="destoryPassAnimation"
    ></WebPassAnimation>
    <base-header
      :title="gameRank"
      :is-title-bold="true"
      :left-arrow="$device.isMobile"
      :left-text="baseHeaderLeftText"
      :back-on-click-left="false"
      right-icon="Setting"
      @on-click-left="goBack"
      @on-click-right="goSetting"
    ></base-header>
    <div
      v-if="$device.isMobile"
      class="wrap-victory-condition text-center text-12 text-white"
      :class="$isJungo ? 'bg-secondary' : 'bg-accent'"
    >
      <template v-if="$isJungo">
        盤上にある石を数え、多いほうが勝ちとなります。
      </template>
      <template v-else>
        {{ $t('勝利條件') + ' : ' }}
        <span>{{
          $t('黑') + victoryCondition[gameData.boardSize] + $t('子')
        }}</span>
      </template>
    </div>
    <div
      class="game-practice px-3 p-md-4 d-flex flex-column flex-md-row-reverse justify-content-center align-items-center"
    >
      <div
        class="wrap-operate-panel"
        :class="{
          'bg-bgcontainer': gameData.createdTime && !$device.isMobile,
          'bg-white': !gameData.createdTime && !$device.isMobile,
        }"
      >
        <div
          v-if="gameData.createdTime || winningWay"
          class="operate-panel d-md-flex flex-md-column justify-content-md-between"
        >
          <div>
            <div
              v-if="!$device.isMobile"
              class="wrap-victory-condition text-center text-white bg-secondary"
              :class="$isJungo ? 'px-3' : 'text-18 font-weight-bold'"
            >
              <template v-if="$isJungo"
                >盤上にある石を数え、多いほうが勝ちとなります。</template
              >
              <template v-else>
                {{ $t('勝利條件') + ' : ' }}
                <span>{{
                  $t('黑') + victoryCondition[gameData.boardSize] + $t('子')
                }}</span>
              </template>
            </div>
            <b-row
              v-if="$device.isMobile ? !isModalJudgementResultShow : true"
              class="wrap-user mb-2 mb-md-0 px-md-2"
            >
              <b-col
                cols="6"
                md="12"
                class="p-0 pr-2 pr-md-0 card-user my-md-2"
              >
                <b-card
                  bg-variant="blackChess"
                  text-variant="white"
                  border-variant="white"
                  class="position-relative"
                >
                  <div class="d-flex flex-nowrap align-items-center">
                    <b-img
                      class="avatar"
                      rounded="circle"
                      :srcset="blackPlayerAvatar"
                      alt="avatar"
                    ></b-img>
                    <div class="wrap-user-details d-flex flex-column">
                      <p class="user-name m-0">
                        {{ blackPlayer.nickName }}
                      </p>
                      <p class="user-detail m-0">
                        {{ $isJungo ? $t('黑') : $t('提子') }}：
                        {{
                          $isJungo ? boardCount.blackBoardCount : capCount.black
                        }}
                      </p>
                    </div>
                  </div>
                  <span
                    v-show="turnColor === 'black'"
                    class="player-turn-mark"
                    :class="$isJungo ? 'bg-accent-2' : 'bg-accent'"
                  ></span>
                </b-card>
              </b-col>
              <b-col cols="6" md="12" class="p-0 card-user">
                <b-card
                  border-variant="white"
                  class="card-shadow position-relative"
                >
                  <div class="d-flex flex-nowrap align-items-center">
                    <b-img
                      class="avatar"
                      rounded="circle"
                      :srcset="whitePlayerAvatar"
                      alt="avatar"
                    ></b-img>
                    <div class="wrap-user-details d-flex flex-column">
                      <p class="user-name m-0">
                        {{ whitePlayer.nickName }}
                      </p>
                      <p class="user-detail m-0">
                        {{ $isJungo ? $t('白') : $t('提子') }}：
                        {{
                          $isJungo ? boardCount.whiteBoardCount : capCount.white
                        }}
                      </p>
                    </div>
                  </div>
                  <span
                    v-show="turnColor === 'white'"
                    class="player-turn-mark"
                    :class="$isJungo ? 'bg-accent-2' : 'bg-accent'"
                  ></span>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <ChessboardToolbar
            v-if="!$device.isMobile && !isModalJudgementResultShow"
            :mode="chessboardToolbarMode"
            :step-type="stepType"
            :current-step="currentStep"
            :max-step="maxStep"
            :has-support-game="
              ['practiceGame', 'courseGame'].includes(mode) && !$isJungo
            "
            :has-influence="
              ['practiceGame', 'courseGame'].includes(mode) && !$isJungo
            "
            :has-judge="!$isJungo"
            :support-count="supportCount"
            @item-click="onToolbarClick"
            @move-chess="moveChess"
            @check-answer-tab="checkAnswerTab"
            @step-change="goto"
          ></ChessboardToolbar>
          <b-row
            v-if="!$device.isMobile && isModalJudgementResultShow"
            class="modal-judgement-result w-100 m-0 p-2"
          >
            <b-col cols="12" class="p-0 position-relative">
              <b-card
                border-variant="secondary"
                class="judgement-result-card card-shadow position-relative"
              >
                <div class="d-flex flex-column align-items-center m-3">
                  <p class="judgement-result mb-3 text-16">
                    {{ winningWay }}
                  </p>
                  <div class="d-flex justify-content-center align-items-center">
                    <b-button
                      :variant="$isJungo ? 'primary' : 'secondary'"
                      size="md"
                      class="mr-3"
                      @click="checkJudgementResult('cancel')"
                    >
                      {{ $t('取消') }}
                    </b-button>
                    <b-button
                      :variant="$isJungo ? 'secondary' : 'primary'"
                      size="md"
                      @click="checkJudgementResult('confirm')"
                    >
                      {{ $t('確定') }}
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <AiGame
          v-if="
            !gameData.createdTime &&
            !winningWay &&
            !$device.isMobile &&
            mode === 'practiceGame'
          "
          @onSelectedAiCharacter="onSelectedAiCharacter"
        ></AiGame>
      </div>
      <b-row class="chessboard pr-md-4 m-0">
        <b-col cols="12" class="p-0 position-relative">
          <Chessboard
            v-if="isTestPlay"
            ref="chessboard"
            mode="board"
            toolbar="game"
            :board-size="selectedAiCharacter.boardSize || gameData.boardSize"
            :sgf="gameData.sgf"
            :hide-tool-bar="!$device.isMobile"
            @current-step-change="onCurrentStepChange"
            @max-step-change="onMaxStepChange"
          ></Chessboard>
          <Chessboard
            v-else
            ref="chessboard"
            :key="chessboardKey"
            :mode="gameRoomChessboardMode"
            :is-verification="mode === 'verificationGame'"
            :board-size="selectedAiCharacter.boardSize || gameData.boardSize"
            :sgf="gameData.sgf"
            toolbar="game"
            :hide-tool-bar="!$device.isMobile"
            :has-support-game="
              ['practiceGame', 'courseGame'].includes(mode) && !$isJungo
            "
            :has-influence="
              ['practiceGame', 'courseGame'].includes(mode) && !$isJungo
            "
            :has-judge="!$isJungo"
            :is-support-step="isSupportStep"
            :support-count="supportCount"
            :is-locked="
              !gameData.createdTime &&
              !winningWay &&
              !$device.isMobile &&
              mode === 'practiceGame'
            "
            @on-judge="onJudge"
            @board-grid-change="onBoardGridChange"
            @cap-count-change="onCapCountChange"
            @turn-color-change="onTurnColorChange"
            @step-type-change="onStepTypeChange"
            @current-step-change="onCurrentStepChange"
            @max-step-change="onMaxStepChange"
            @chessboard-toolbar-mode-change="onChessboardToolbarModeChange"
            @ai-pass="onAiPass"
            @game-over="onGameOver"
            @complete-step-change="onCompleteStepChange"
            @support-game="supportGame"
          ></Chessboard>
        </b-col>
      </b-row>
    </div>
    <modal-result
      v-if="isModalGameResultShow"
      :mode="mode"
      :is-success="isWin"
      :result-content="winningWay"
      :has-reviewed="completeStep >= 20"
      @item-click="onModalResultClick"
    ></modal-result>
    <modal-wrapper
      v-if="isSettingShow && !$device.isMobile"
      :is-modal-wrapper-show="isSettingShow"
      :is-show-transition="true"
      :click-to-close="true"
      @close="isSettingShow = false"
    >
      <Setting
        v-if="isSettingShow"
        @close="isSettingShow = false"
        @click.native.stop=""
      ></Setting>
    </modal-wrapper>
  </div>
</template>

<script>
import _ from 'lodash';

import Chessboard from '@/components/Chessboard/Chessboard.vue';
import ChessboardToolbar from '@/components/Chessboard/ChessboardToolbar.vue';
import ModalResult from '@/components/Modal/ModalResult.vue';
import PassAnimation from '@/components/Base/PassAnimation.vue';
import WebPassAnimation from '@/components/Base/WebPassAnimation.vue';
import BaseHeader from '@/components/Base/BaseHeader';
import ModalWrapper from '@/components/Base/ModalWrapper.vue';

import AiGame from '@/views/Practice/AiGame.vue';
import Setting from '@/views/Personal/RoomSetting.vue';

import delay from '@/lib/base/delay.js';
import aiLevelMapping from '@/lib/base/aiLevel.js';
import BackEvent from '@/lib/base/backEvent.js';
import {sgfToNumber} from 'goer-utils/function/parser';

import aiCharacterMapping from '@/json/aiCharacter.json';
import rankList from '@/json/rank.json';

import aiGameService from '@/services/aiGame.js';

import {aiGame} from '@/constant/env';

export default {
  name: 'GameRoom',
  components: {
    Chessboard,
    ModalResult,
    PassAnimation,
    WebPassAnimation,
    BaseHeader,
    ChessboardToolbar,
    ModalWrapper,
    Setting,
    AiGame,
  },
  props: {
    mode: {
      type: String,
      default: 'practiceGame',
      validator: (val) => {
        return ['practiceGame', 'courseGame', 'verificationGame'].includes(val);
        // 練習、課程、檢定
      },
    },
  },
  data() {
    return {
      aiCharacterMapping,
      isPassAnimation: false,
      isLeave: false,
      chessboardKey: 0,
      rankList,
      capCount: {
        black: 0,
        white: 0,
      },
      boardCount: {
        blackBoardCount: 0,
        whiteBoardCount: 0,
      },
      turnColor: 'black',
      stepType: '',
      isWin: false,
      winningWay: '',
      modal: '',
      isModalJudgementResultShow: false,
      isModalGameResultShow: false,
      isModalMessageBoxShow: false,
      victoryCondition: aiGame.victoryCondition,
      currentStep: 0,
      maxStep: 0,
      chessboardToolbarMode: 'game',
      selectedAiCharacter:
        JSON.parse(localStorage.getItem('selectedAiCharacter')) || {},
      gameRoomChessboardMode: 'game',
      isSettingShow: false,
      completeStep: 0,
      supportCount: this.$store.state.aiGame.supportCount || 0,
      supportStep: null,
      isTestPlay: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userColor() {
      return this.gameData.userColor;
    },
    blackPlayer() {
      if (this.userColor === 'black') {
        return this.user.userData;
      } else {
        // 防止prettier自動換行產生空白
        // prettier-ignore
        return {
          nickName: this.gameData.opponentName,
          rank: aiLevelMapping.processAiLevel(this.gameData.aiLevel),
          avatar:
            this.gameData.opponentName !== '黑小嘉'
              ? `avatar_tsumego_ai_${aiCharacterMapping[this.gameData.opponentName] || 1}`
              : 'ai_b',
        };
      }
    },
    whitePlayer() {
      if (this.userColor === 'white') {
        return this.user.userData;
      } else {
        // 防止prettier自動換行產生空白
        // prettier-ignore
        return {
          nickName: this.gameData.opponentName,
          rank: aiLevelMapping.processAiLevel(this.gameData.aiLevel),
          avatar:
            this.gameData.opponentName !== '白小嘉'
              ? `avatar_tsumego_ai_${aiCharacterMapping[this.gameData.opponentName] || 1}`
              : 'ai_w',
        };
      }
    },
    whitePlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.whitePlayer.avatar}.png?srcset`);
    },
    blackPlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.blackPlayer.avatar}.png?srcset`);
    },
    gameData() {
      return this.$store.state.aiGame;
    },
    gameRank() {
      switch (this.mode) {
        case 'practiceGame':
          return this.$isJungo ? this.$t('AI對弈') : this.$t('下棋');
        case 'courseGame':
          return this.$t('課程－下棋');
        case 'verificationGame':
          return this.$t('檢定－下棋');
        default:
          return this.$t('下棋');
      }
    },
    hasUnfinishedGame() {
      return this.$store.getters['aiGame/hasUnfinishedGame'];
    },
    baseHeaderLeftText() {
      if (!this.$device.isMobile) {
        return this.mode === 'verificationGame'
          ? this.$t('返回上一頁')
          : this.$t('返回首頁');
      } else {
        return '';
      }
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    isSupportStep() {
      return this.supportStep === this.completeStep;
    },
  },
  watch: {
    async 'gameData.createdTime'(newValue, oldValue) {
      if (oldValue == null && newValue) {
        this.startPassAnimation();
      }
    },
    'gameData.supportCount'(newValue) {
      if (newValue) this.supportCount = newValue;
    },
  },
  async created() {
    if (!this.gameData.createdTime) {
      this.$store.commit('env/setIsLoading', true);
      if (this.mode === 'practiceGame') {
        await this.getUnfinishedGame();
      } else if (this.mode === 'courseGame') {
        await this.getUnfinishedCourseGame();
        this.startPassAnimation();
      } else if (this.mode === 'verificationGame') {
        this.$store.commit('env/setIsLoading', false);
        return this.$router.push({name: 'verification'});
      }
    } else {
      if (
        (this.hasUnfinishedGame ||
          this.mode === 'courseGame' ||
          this.mode === 'verificationGame') &&
        !this.$device.isMobile
      ) {
        this.isPassAnimation = true;
        await delay(1000);
        this.isLeave = true;
      }
    }

    if (this.hasUnfinishedGame)
      this.$Message.warning(this.$t('將繼續上次未下完的棋局'));
  },
  methods: {
    // 對弈事件處理
    onAiPass() {
      this.$Message.warning(this.$t('對方 Pass'));
    },
    onBoardGridChange(boardGrid) {
      let blackBoardCount = 0;
      let whiteBoardCount = 0;
      const arr = _.flatten(boardGrid);
      arr.forEach((value) => {
        if (value === 1) {
          blackBoardCount += 1;
        } else if (value === -1) {
          whiteBoardCount += 1;
        }
      });
      this.boardCount.blackBoardCount = blackBoardCount;
      this.boardCount.whiteBoardCount = whiteBoardCount;
    },
    onCapCountChange(captureCount) {
      this.capCount = captureCount;
    },
    onTurnColorChange(turnColor) {
      this.turnColor = turnColor;
    },
    onStepTypeChange(stepType) {
      this.stepType = stepType;
    },
    onCurrentStepChange(currentStep) {
      this.currentStep = currentStep;
    },
    onMaxStepChange(maxStep) {
      this.maxStep = maxStep;
    },
    onChessboardToolbarModeChange(chessboardToolbarMode) {
      this.chessboardToolbarMode = chessboardToolbarMode;
    },
    onCompleteStepChange(step) {
      this.completeStep = step;
    },
    async onGameOver({isWin, winningWay}) {
      BackEvent.addEvent(() => {
        this.goBack();
      });
      this.isModalGameResultShow = true;
      this.isWin = isWin;
      this.winningWay = winningWay;
    },
    onJudge(information) {
      this.isModalJudgementResultShow = information.isModalJudgementResultShow;
      this.winningWay = information.winningWay;
    },
    async onModalResultClick(value) {
      this.isModalGameResultShow = false;
      BackEvent.popEvent();
      if (value === 'restartPractice') {
        if (this.$device.isMobile) {
          this.$store.commit('env/setIsLoading', true);
          await this.createGamePractice();
        } else {
          this.chessboardToolbarMode = 'game';
          this.resetGame();
          this.onSelectedAiCharacter(this.selectedAiCharacter);
        }
      } else if (value === 'restartLesson') {
        this.$store.commit('env/setIsLoading', true);
        await this.createCourseGame();
      } else if (value === 'return' && this.mode === 'practiceGame') {
        if (this.$device.isMobile) {
          this.$router.push('/practice/gamelist');
        } else {
          this.chessboardToolbarMode = 'game';
          this.resetGame();
          this.onSelectedAiCharacter(this.selectedAiCharacter);
        }
      } else if (value === 'return' && this.mode === 'courseGame') {
        this.$router.push('/');
      } else if (value === 'confirm' && this.mode === 'verificationGame') {
        this.$router.push({name: 'verification'});
      } else if (value === 'reviewGame') {
        return this.$router.push({
          name: 'kifu-preview',
          params: {
            createdTime: this.gameData.overGameCreatedTime,
            gameMode: this.mode,
          },
          query: {isGameOverReivewed: true},
        });
      }
    },
    async resetGame() {
      this.isModalJudgementResultShow = false;
      this.winningWay = '';
      this.supportStep = null;
      this.supportCount = this.gameData.supportCount;
    },
    destoryPassAnimation() {
      this.isPassAnimation = false;
      this.isLeave = false;
    },
    async createGamePractice() {
      const userColor = _.sample(['black', 'white']);
      const opponentName = this.gameData.opponentName;
      const style = this.$store.state.style;
      const aiId = this.gameData.aiId;
      const data = {
        userColor,
        aiLevel: this.gameData.aiLevel,
        boardSize: this.gameData.boardSize,
        opponentName,
        style,
        coursePlan: this.currentPlan.id,
        aiId,
      };
      await this.$store.dispatch('aiGame/createPracticeGame', data);
      this.$store.commit('env/setIsLoading', false);
      this.resetGame();
    },
    async createCourseGame() {
      const courseUniqueKey = this.$route.params.courseUniqueKey;
      const coursePlan = this.currentPlan.id;
      await this.$store.dispatch('aiGame/createCourseGame', {
        courseUniqueKey,
        coursePlan,
      });
      this.$store.commit('env/setIsLoading', false);
      this.resetGame();
    },
    goBack() {
      BackEvent.clearEvents();
      if (['courseGame', 'practiceGame'].includes(this.mode)) {
        return this.$router.push('/');
      } else if (this.mode === 'verificationGame') {
        this.$router.push('/verification');
      }
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
      if (this.hasUnfinishedGame) {
        this.startPassAnimation();
      }
    },
    async getUnfinishedCourseGame() {
      const courseUniqueKey = this.$route.params.courseUniqueKey;
      await this.$store.dispatch('aiGame/getUnfinishedCourseGame', {
        courseUniqueKey,
      });
      this.$store.commit('env/setIsLoading', false);
    },
    // getUnfinishedVerificationGame() {
    //   const gameData = {
    //     verificationId: this.$route.params.verificationId,
    //     gameId: this.$route.params.gameId,
    //   };
    //   this.$store
    //     .dispatch('aiGame/getUnfinishedVerificationGame', gameData)
    //     .then(() => {
    //       this.$store.commit('env/setIsLoading', false);
    //     });
    // },
    goSetting() {
      return this.$device.isMobile
        ? this.$router.push({name: 'roomSetting'})
        : (this.isSettingShow = true);
    },
    onSelectedAiCharacter(aiCharacter) {
      this.selectedAiCharacter = aiCharacter;
      this.gameRoomChessboardMode = 'board';
      this.chessboardKey += 1;
    },
    async startPassAnimation() {
      this.isPassAnimation = true;
      await delay(1000);
      this.gameRoomChessboardMode = 'game';
      this.chessboardKey += 1;
      this.isLeave = true;
    },
    // about chessboardtoolbar
    async onToolbarClick(event) {
      if (event === 'testPlay') {
        this.isTestPlay = !this.isTestPlay;
        this.chessboardToolbarMode = this.isTestPlay ? 'testPlay' : 'game';
        await this.$nextTick();
        if (this.isTestPlay) this.$refs.chessboard.onToolbarClick(event);
      } else {
        this.$refs.chessboard.onToolbarClick(event);
      }
    },
    moveChess(direction) {
      this.$refs.chessboard.moveChess(direction);
    },
    checkAnswerTab(value) {
      this.$refs.chessboard.checkAnswerTab(value);
    },
    goto(step) {
      this.$refs.chessboard.goto(step);
    },
    checkJudgementResult(value) {
      this.$refs.chessboard.checkJudgementResult(value);
    },
    async supportGame() {
      if (this.isLoading) return;
      if (this.userColor !== this.turnColor) {
        return this.$Message.warning(this.$t('還沒輪到你哦'));
      }
      if (this.isSupportStep) {
        return this.$Message.warning(this.$t('您已使用落點提示'));
      }
      if (this.supportCount > 0) {
        this.supportStep = this.completeStep;
        this.$store.commit('env/setIsLoading', true);
        const {options} = await aiGameService.supportGame(this.gameData.id);
        this.$store.commit('env/setIsLoading', false);
        if (options.length > 0) {
          this.supportCount--;
          options.forEach((option, index) => {
            const path = sgfToNumber(option, this.gameData.boardSize);
            const text = index === 0 ? 'A' : index === 1 ? 'B' : 'C';
            this.$refs.chessboard.addMarkup({
              path,
              type: 'LB',
              text,
            });
          });
        } else {
          return this.$Message.warning(this.$t('對局已結束，可以算輸贏囉！'));
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'roomSetting') {
      const key =
        this.$vnode.key == null
          ? this.$vnode.componentOptions.Ctor.cid +
            (this.$vnode.componentOptions.tag
              ? `::${this.$vnode.componentOptions.tag}`
              : '')
          : this.$vnode.key;
      const cache = this.$vnode.parent.componentInstance.cache;
      const keys = this.$vnode.parent.componentInstance.keys;
      if (cache[key]) {
        if (keys.length) {
          const index = keys.indexOf(key);
          if (index > -1) {
            keys.splice(index, 1);
          }
        }
        delete cache[key];
      }
      this.$destroy();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.wrap-game-practice {
  background: $bgsection;
  .game-practice {
    min-height: calc(100% - 56px - 18px);
    @media screen and (min-width: 768px) {
      max-width: 1440px;
      margin: 0 auto;
      min-height: calc(100% - 56px);
      height: calc(100% - 56px);
    }
  }
  .wrap-operate-panel {
    width: 100%;
    @media screen and (min-width: 768px) {
      min-width: 289px;
      width: 289px;
      margin: 0;
      height: 100%;
      border-radius: $rounded-md;
      padding: 16px;
    }
    @media screen and (min-width: 1440px) {
      min-width: 446px;
      width: 446px;
    }
    .operate-panel {
      @media screen and (min-width: 768px) {
        background: $bgsection;
        height: 100%;
        border-radius: $rounded-md;
      }
      .wrap-victory-condition {
        @media screen and (min-width: 768px) {
          padding: 5px 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          line-height: 30px;
        }
      }
    }
    .judgement-result-card {
      border: 3px solid $secondary;
    }
  }
  .wrap-user {
    width: 100%;
    margin: 0;
    .avatar {
      width: 40px;
      height: 40px;
      margin: 8px;
      @media screen and (min-width: 768px) {
        width: 80px;
        height: 80px;
        margin: 10px 16px;
      }
    }
    .wrap-user-details {
      max-width: calc(100% - 40px - 24px);
      .user-name {
        font-size: 16px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (min-width: 768px) {
          font-size: 20px;
          line-height: 34px;
        }
      }
      .user-detail {
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 20px;
          line-height: 34px;
        }
      }
    }
  }
  .chessboard {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  .player-turn-mark {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: 4px;
    @media screen and (min-width: 768px) {
      width: 24px;
      height: 24px;
      right: 8px;
      top: 8px;
    }
  }
}
</style>
